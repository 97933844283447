<template>
	<img v-if="image === null" id="awo-logo" src="@/assets/awo.svg" width="75" alt="AWO Logo" />
	<div class="card">
		<div v-if="image !== null" class="card-img-top" style="overflow: hidden;">
			<v-stage ref="stage" :config="stageSize">
				<v-layer ref="layer">
					<v-image
						:config="{
							image: image,
							...stageSize,
						}"
					/>
					<v-image
						ref="awoLogo"
						:config="awoLogoConfig"
					/>
					<v-image
						ref="topLeftLogo"
						:config="topLeftLogoConfig"
					/>
					<v-label
						ref="label"
						@dragstart="handleDragStart"
						@dragend="handleDragEnd"
						:config="{
							x: Math.floor(stageSize.width / 2),
							y: 50,
							draggable: true,
						}"
					>
						<v-tag :config="{
							fill: `rgba(255, 255, 255, ${isDragging ? '0.65' : '0.5'})`,
							padding: 10,
							lineJoin: 'round',
							// cornerRadius: 2,
						}" />
						<v-text :config="{ ...textConfig, text: text || 'Dein Text' }"></v-text>
					</v-label>
				</v-layer>
			</v-stage>
		</div>
		<div v-if="!image" ref="cardBody" class="card-body">
			<h5 class="card-title">AWO Bild-Generator</h5>
			<div v-if="!showCamera">
				<div class="row">
          <div class="col-md-5">
            <label for="image-select" class="form-label">
              Bitte wähle ein Foto aus, um fortzufahren.
            </label>
            <input id="image-select" type="file" class="form-control" @change="onSelectImage" />
          </div>
          <div class="col-md-2 text-center">
            <span v-if="cameraAvailable">oder</span>
          </div>
          <div class="col-md-5">
            <label v-if="cameraAvailable" for="take-picture" class="form-label">
              Nimm' ein Foto mit Deiner Kamera auf.
            </label><br />
            <button v-if="cameraAvailable" class="btn btn-primary text-white" @click="showCamera = true">
              Kamera öffnen
            </button>
            <div v-else class="alert alert-warning" role="alert">
              Leider konnte keine Kamera gefunden werden, oder die
              erforderliche Berechtigung wurde nicht erteilt.
            </div>
          </div>
        </div>
			</div>
			<div v-else>
				<camera ref="cameraItem" autoplay @error="cameraAvailable = false; showCamera = false" />
				<button class="btn btn-primary" @click="capture">Aufnehmen</button>
				<button class="btn btn-light ms-2" @click="showCamera = false">Abbrechen</button>
			</div>
		</div>
		<div v-else class="card-body">
			<div class="d-flex justify-content-start flex-wrap">
				<input
					v-model="text"
					type="text"
					class="form-control flex-grow-1 flex-shrink-1 m-1"
					placeholder="Dein Text"
					style="min-width: 200px; flex-basis: 250px;"
				/>
				<button class="btn btn-light flex-grow-0 flex-shrink-0 m-1" @click="centerText">
					<img src="@/assets/icons/align-horizontal.svg" width="12" alt="Horizontal zentrieren" />
				</button>
				<button class="btn btn-light flex-grow-0 flex-shrink-0 m-1" @click="verticalCenterText">
					<img src="@/assets/icons/align-vertical.svg" width="12" alt="Vertical zentrieren" />
				</button>
				<button class="btn btn-primary flex-grow-0 flex-shrink-0 text-white m-1" @click="exportImage">
					<img src="@/assets/icons/download.svg" width="12" alt="Download" />
					Bild herunterladen
				</button>
			</div>
			<label for="font-size-range" class="form-label m-1 mt-3">Schriftgröße</label>
			<input
				v-model="textConfig.fontSize"
				id="font-size-range"
				type="range"
				class="form-range m-1"
				min="10"
				max="50"
			/>
		</div>
	</div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
// import Konva from 'konva'

const awoLogoFile = require('@/assets/awo-badge.png')
const topLeftLogoFile = require('@/assets/sommer.png')

const stage = ref(null)
const cardBody = ref(null)

const cameraItem = ref(null)

const image = ref(null)
const layer = ref(null)
const awoLogo = ref(null)
const label = ref(null)
const text = ref(null)
const isDragging = ref(false)
const cameraAvailable = ref(true)
const showCamera = ref(false)
const stageSize = reactive({
	width: 50,
	height: 50,
})

const awoLogoConfig = reactive({
	x: -75,
	y: 20,
	image: new Image(),
	width: 125,
	height: 75,
})
const topLeftLogoConfig = reactive({
	x: 20,
	y: 20,
	image: new Image(),
	width: 250,
	height: 125,
})
const textConfig = reactive({
	fill: '#000',
	padding: 10,
	fontSize: 20,
	fontStyle: 'bold',
})

const centerText = () => {
	const stageCenter = Math.floor(stageSize.width / 2)
	const labelWidth = label.value.getNode()
		.getWidth()

	label.value.getNode()
		.x(stageCenter - (labelWidth / 2))
}

const verticalCenterText = () => {
	const stageCenter = Math.floor(stageSize.height / 2)
	const labelHeight = label.value.getNode()
		.getHeight()

	label.value.getNode()
		.y(stageCenter - (labelHeight / 2))
}

const handleImage = (url) => {
	const img = new Image()
	img.src = url

	img.onload = () => {
		stageSize.width = cardBody.value.clientWidth
		stageSize.height = stageSize.width * (img.height / img.width)
		console.log('stageSize', stageSize)
		image.value = img

		awoLogoConfig.image.src = awoLogoFile
		awoLogoConfig.x = stageSize.width - awoLogoConfig.width

		topLeftLogoConfig.image.src = topLeftLogoFile

		setTimeout(() => {
			centerText(true)
			label.value.getNode().y(stageSize.height * 0.8)
		}, 100)
	}
}

const onSelectImage = (e) => {
	console.log('on select image')
	const URL = window.webkitURL || window.URL
	const url = URL.createObjectURL(e.target.files[0])
	handleImage(url)
}

watch(text, () => {
	setTimeout(centerText, 50)
})

watch(textConfig, () => {
	setTimeout(centerText, 50)
})

const handleDragStart = () => {
	isDragging.value = true
}

const handleDragEnd = () => {
	isDragging.value = false
}

const exportImage = () => {
	const dataURL = stage.value.getStage().toDataURL({
		pixelRatio: 2, // or other value you need
	})

	const link = document.createElement('a')
	link.download = 'Bild.png'
	link.href = dataURL
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

const capture = async () => {
	if (!cameraItem.value) {
		return
	}

	const blob = await cameraItem.value?.snapshot()
	handleImage(URL.createObjectURL(blob))
	showCamera.value = false
}
</script>
