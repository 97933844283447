<template>
	<div class="container">
		<image-canvas />
	</div>
</template>

<script>
import ImageCanvas from '@/components/ImageCanvas.vue'

export default {
	name: 'App',
	components: {
		ImageCanvas,
	},
}
</script>

<style lang="scss">
$enable-rounded: false;
$theme-colors: (
  "primary": #d02e26
);
@import '../node_modules/bootstrap/scss/bootstrap';

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//text-align: center;
	//color: #2c3e50;
	margin-top: 60px;
}

#awo-logo {
	display: block;
	margin: 1rem auto;
}

input[type=range]::-webkit-slider-thumb {
  background: #d02e26;
  -webkit-appearance: none;
}
input[type=range]::-moz-range-thumb {
  background: #d02e26;
}
input[type=range]::-ms-thumb {
  background: #d02e26;
}
</style>
